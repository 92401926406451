<template>
    <v-container>
        <v-row>
            <v-col
            class="mx-auto"
            cols="12"
            sm="8">
                <div class="d-flex justify-space-between">
                    <v-btn
                    class="mb-3"
                    color="primary"
                    @click="redirectBack">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                    <v-btn v-if="this.$store.getters.getRole=='operation' || this.$store.getters.getRole=='sales_manager'" color="primary" @click="redirectUpdate" class="mb-3">
                        Edit
                    </v-btn>
                </div>
                <v-card class="pt-3 pl-3">
                    <v-skeleton-loader
                    ref="skeleton"
                    type="table"
                    v-if="api.isLoading">

                    </v-skeleton-loader>
                    <div v-if="this.teamDescription!=null">
                        <div class="text-h5 font-weight-bold">
                                {{ teamDescription.name }}
                        </div>
                        <p class="ml-3 mt-2">
                            {{ teamDescription.description }}
                        </p>
                        <hr/>
                        <div>
                            <div class="pb-3" >
                                <p class="mt-3 ml-3 font-weight-bold" v-if="this.teamDescription!=null">
                                    Team leader 
                                </p>
                                <p class="mt-3 ml-3" v-if="this.teamDescription!=null">
                                    <v-icon small class="mx-2">
                                        fa-users
                                    </v-icon> 
                                    {{ leader.fname }} {{ leader.lname }}
                                </p>
                                <p class="ml-3">
                                    <v-icon small class="mx-2">
                                        mdi-email
                                    </v-icon>
                                    {{ leader.email }}
                                </p>
                            </div>
                        </div>
                    </div>
                </v-card>
                <v-card class="mt-4">
                    <v-data-table
                    v-if="!api.isLoading"
                    :items = "this.teams"
                    :headers = "this.headersTeam"
                    class="elevation--1 pa-2">
                        <template v-slot:item.memberName="{item}">
                            {{ item.fname }} {{ item.lname }}
                        </template>
                        <template v-slot:top>
                            <v-toolbar color="secondary white--text">
                                Members
                            </v-toolbar>

                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    leader:null,
    teamDescription:null,
    teams:null,
    headersTeam:[
        {
            text:'Name',
            value:'memberName',
            align:'start',
        },{
            text:'Email',
            value:'email',
        }
    ],
    api:{
        isSuccesful:false,
        isLoading:false,
        isError:false,
        error:null,
        sucess:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            this.api.isSuccesful = true;
            this.api.isError = false;
            this.api.isLoading = false;
            if(resp.class=='getTeamByName')
            {
                this.leader = resp.data.leader[0]
                this.teamDescription = resp.data.team[0];
                this.teams = resp.data.members;
            }
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetchTeam(){
            let teamName = this.$_.clone(this.$store.getters.getTeam);
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/team/name/"+teamName;
            return tempApi;
        },
        fetch(){
            let fetchTeamApi = this.fetchTeam();
            this.$axios.all([
                this.$api.fetch(fetchTeamApi),
            ])
        },
        redirectBack(){
            this.$router.push({name:'PageTeamList'})
        },
        redirectUpdate(){
            this.$router.push({
                name:'PageTeamUpdate'
            })
        }
    }
}
</script>